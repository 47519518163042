import React from "react"
import styled from "styled-components"
import media from "../styles/breakPoints"
import { Link } from "gatsby"
import { FiPlusSquare } from "react-icons/fi"

const Button = styled(Link)`
   /* button */
   width: 195px;
   height: 30px;
   text-decoration: none;
   display: inline-flex;
   justify-content: space-between;
   align-items: center;
   background-color: transparent;
   border: 1px solid ${props => props.theme.color.fifthLight2};
   padding: 0 10px;
   cursor: pointer;
   /* label */
   font-family: ${props => props.theme.font.secondy};
   font-weight: 400;
   font-size: ${props => props.theme.size.small};
   line-height: 30px;
   color: ${props => props.theme.color.fifthLight2};
   &.-inverse {
      color: ${props => props.theme.color.grayZero};
      background-color: ${props => props.theme.color.fourthLight1};
      border: none;
   }

   ${media.greaterThan("lg")`
      transition: transform 300ms linear;
      &:hover {
         transform: scale(1.1);
         transition: transform 300ms linear;
      }
   `}
   
`

const ButtonBudget = ({ label, target }) => {
   return (
      <Button to={target}>
         {label}
         <FiPlusSquare 
            size="20px"
            strokeWidth="1px" 
            color={`${props => props.theme.color.fifthLight2}`}
         />
      </Button>
   )
}

export default ButtonBudget