import React from "react";
import { useStaticQuery, graphql } from 'gatsby'

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer"
import BannerHero from "../components/BannerHero"
import CardProduct from "../components/CardProduct"
import CardsSection from "../components/CardsSection"
import BudgetSection from "../components/BudgetSection"
import ShowroomSection from "../components/ShowroomSection"
import ItemGallery from "../objects/ItemGallery"
import NewsSection from "../components/NewsSection"
import FreeShippingSection from "../components/FreeShippingSection"
import { cards, news } from "./content"

const Homepage = () => {

   const imgContent = useStaticQuery (
      graphql`
         query {
            imgBanner: file(relativePath: { eq: "banner-hero.jpg" }) {
               childImageSharp {
                  fluid(maxWidth:2300) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
            imgCards: allFile(
                  sort: {order: ASC, fields: 
                     childImageSharp___fluid___originalName},
                  filter: {relativeDirectory: {eq: "cards"}}) {
               edges {
                 node {
                   childImageSharp {
                     fluid(maxWidth: 350) {
                        ...GatsbyImageSharpFluid
                     }
                   }
                 }
               }
            }
            imgShowroom: file(relativePath: { eq: "visite-showroom.jpg" }) {
               childImageSharp {
                  fluid(maxWidth:2300) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
            imgGallery: allFile(
                  sort: {order: ASC, fields:
                     childImageSharp___fluid___originalName}, 
                  filter: {relativeDirectory: {eq: "news"}}) {
               edges {
                 node {
                   childImageSharp {
                     fluid(maxWidth: 230) {
                        ...GatsbyImageSharpFluid
                     }
                   }
                 }
               }
            }
            imgShipping: file(relativePath: { eq: "frete-gratis.jpg" }) {
               childImageSharp {
                  fluid(maxWidth:1024) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
         }
      `
   )

   const imagesCards = imgContent.imgCards.edges
   const imagesGallery = imgContent.imgGallery.edges

   return (
      <Layout>
         <SEO title="Home"/>
         <Header title="" />
         <BannerHero 
            title="Design, conforto e requinte"
            subtitle="10% de desconto à vista"
            imgFluid={imgContent.imgBanner.childImageSharp.fluid}
         />
         <CardsSection>
            {imagesCards.map((img, i) => {
               return (
                  <CardProduct
                     key={i}
                     target={cards[i].target}
                     imgFluid={img.node.childImageSharp.fluid}
                     alt={cards[i].alt}
                     title={cards[i].title}
                     description={cards[i].description}
                  />
               )
            })}
         </CardsSection>
         <BudgetSection />
         <ShowroomSection 
            text1="Visite nosso showroom"
            text2="Móveis & Decoração"
            imgFluid={imgContent.imgShowroom.childImageSharp.fluid}
         />
         <NewsSection>
            {imagesGallery.map((img, i) => {
               return (
                  <ItemGallery 
                     key={i}
                     target={news[i].target}
                     imgFluid={img.node.childImageSharp.fluid}
                     alt={news[i].alt}
                     title={news[i].title}
                  />
               )
            })}
         </NewsSection>
         <FreeShippingSection 
            imgFluid={imgContent.imgShipping.childImageSharp.fluid}
         />
         <Footer id="contact"/>
      </Layout>
   )
}

export default Homepage;