import React from "react"

import { BannerSection,
         WrapBanner,
         OverlayArea,
         OverlayContent,
         Title,
         Subtitle,
         IconArrowDown,
         BottomBar } from "./styles"

const BannerHero = ({ className, title, subtitle, description, imgFluid }) => {

   return (
      <BannerSection className={className}>
         <WrapBanner className={className} fluid={imgFluid}>
            <OverlayArea className={className}/>
            <OverlayContent className={className}>
               <Title>{title}</Title>
               <Subtitle>{subtitle}</Subtitle>
            </OverlayContent>
         </WrapBanner>
         <BottomBar className={className}>
            <IconArrowDown />
         </BottomBar>
      </BannerSection>
   )
}

export default BannerHero