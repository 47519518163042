import styled from "styled-components";
import media from "../../styles/breakPoints"
import BackgroundImage from "gatsby-background-image"

import MainSection from "../../objects/MainSection" 

const ContainerSection = styled(MainSection)`
   width: 100%;
   height: 250px;
   margin: 0;
   
   ${media.greaterThan("sm")`
      height: 300px;
   `}

   ${media.greaterThan("md")`
      height: 416px;
   `}

`

const BkgImage = styled(BackgroundImage)`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
`

const Text = styled.span`
   position: absolute;
   top: 40%;
   font-family: ${props => props.theme.font.secondy};
   font-weight: 400;
   font-size: ${props => props.theme.size.small};
   line-height: 2rem;
   color: ${props => props.theme.color.grayZero};
   background-color: ${props => props.theme.color.thirdBase};
   padding: 5px 10px;
   &.-variant {
      top: calc(40% + 44px);
   }

   ${media.greaterThan("sm")`
      top: 44%;
      &.-variant {
         top: calc(44% + 44px);
      }
   `}

   ${media.greaterThan("md")`
      padding: 5px 10px 5px 30px;
   `}

   ${media.greaterThan("lg")`
      top: 50%;
      font-size: ${props => props.theme.size.mediumSmall};
      line-height: 2.4rem;
      padding: 5px 15px 5px 60px;
      &.-variant {
         top: calc(50% + 50px);
      }
   `}

`

export {
   ContainerSection,
   BkgImage,
   Text
}