import React from "react"

import { ContainerSection,
         BkgImage,
         Text } from "./styles"

const ShowroomSection = ({ text1, text2, imgFluid }) => {

   return (
      <ContainerSection>
         <BkgImage fluid={imgFluid}>
               <h3 style={{ margin: 0}}><Text>{text1}</Text></h3>
               <h4 style={{ margin: 0}}><Text className="-variant">{text2}</Text></h4>
         </BkgImage>
      </ContainerSection>
   )
}

export default ShowroomSection