import React from "react";
import styled from "styled-components"
import media from "../styles/breakPoints"
import { Link } from "gatsby";
import Img from "gatsby-image"

const WrapItem = styled.li`
   position: relative;
   list-style: none;
   width: 100%;
   height: auto;
   margin: 0;
`

const ImageItem = styled(Img)`
   width: 100%;
   height: 100%;
`

const LinkItem = styled(Link)`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border: none;
   outline: none;
   text-decoration: none;
   cursor: pointer;
   pointer-events: auto;
   z-index: 10;
   background-color: rgba(57,47,45, 0.8); /* primaryDark2 */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   opacity: 0;
   &.-notpointer {
      cursor: default;
      pointer-events: none;
      z-index: 0;
   }

   ${media.greaterThan("lg")`
      transition: all 300ms linear;
      &:hover {
         opacity: 1;
         transition: opacity 300ms linear;
      }
   `}

`

const Overlay = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(57,47,45, 0.8); /* primaryDark2 */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   opacity: 0;
   z-index: 5;

   ${media.greaterThan("lg")`
      transition: all 300ms linear;
      &:hover {
         opacity: 1;
         transition: opacity 300ms linear;
      }
   `}

`

const TitleItem = styled.h4`
   font-family: ${props => props.theme.font.primary};
   font-size: ${props => props.theme.size.small};
   font-weight: 600;
   color: ${props => props.theme.color.grayZero};
   margin-bottom: 20px;
`

const ActionItem = styled.p`
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.verySmall};
   font-weight: 400;
   color: ${props => props.theme.color.grayZero};
`

const ItemGallery = ({ className, target, title, imgFluid, alt }) => {

   return (
      <WrapItem className={className}>
         <ImageItem fluid={imgFluid} alt={alt} />
         <LinkItem 
            to={!target ? "/" : target}
            className={!target ? "-notpointer" : ""}>
            <TitleItem>{title}</TitleItem>
            <ActionItem>clique para saber mais</ActionItem>
         </LinkItem>
         <Overlay>
            <TitleItem>{title}</TitleItem>
            <ActionItem>clique para saber mais</ActionItem>
         </Overlay>
      </WrapItem>
   )
}

export default ItemGallery