import styled from "styled-components"
import media from "../../styles/breakPoints"

const WrapSection = styled.ul`
   width: 100%;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-template-rows: repeat(3, 1fr);
   grid-gap: 15px;
   padding: 15px;
   margin: 0;

   ${media.greaterThan("md")`
      max-width: 768px;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 20px;
      padding: 20px;
   `}

`

export {
   WrapSection
}