import React from "react";

import MainSection from "../../objects/MainSection"
import { WrapSection } from "./styles"

const NewsSection = ({ className, children }) => {

   return (
      <MainSection>
         <WrapSection className={className}>
            {children}
         </WrapSection>
      </MainSection>
   )
}

export default NewsSection