import styled from "styled-components";
import media from "../../styles/breakPoints"
import BackgroundImage from "gatsby-background-image"

import MainSection from "../../objects/MainSection" 
import { FaArrowCircleDown } from "react-icons/fa"

const BannerSection = styled(MainSection)`
   position: relative;
   width: 100%;
   height: calc(100vh - 90px);
   margin: 0;

   ${media.greaterThan("lg")`
      height: calc(100vh - 40px);
   `}

`

const WrapBanner = styled(BackgroundImage)`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: calc(100vh - 160px);
   display: flex;
   justify-content: center;
   align-items: center;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;

   ${media.greaterThan("lg")`
      height: calc(100vh - 110px);
   `}

`

const OverlayArea = styled.div`
   position: absolute;
   top: 100px;
   left: 0;
   width: 100%;
   height: 90px;
   display: flex;
   background-color: ${props => props.theme.color.primaryLight1};
   opacity: 0.8;
   mix-blend-mode: multiply;

   ${media.greaterThan("lg")`
      top: 50%;
      transform: translateY(-50%);
      height: 128px;
   `}

`

const OverlayContent = styled.div`
   position: absolute;
   top: 100px;
   left: 0;
   width: 100%;
   height: 90px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   ${media.greaterThan("lg")`
      top: 50%;
      transform: translateY(-50%);
      height: 128px;
   `}

`

const Title = styled.h1`
   font-family: ${props => props.theme.font.primary};
   font-weight: 600;
   font-size: ${props => props.theme.size.medium};
   line-height: 3rem;
   color: ${props => props.theme.color.fourthLight2};
   margin: 0;

   ${media.greaterThan("lg")`
      font-size: ${props => props.theme.size.veryBig};
      margin-bottom: 3rem;
   `}

`

const Subtitle = styled.h2`
   font-family: ${props => props.theme.font.secondy};
   font-weight: 400;
   font-size: ${props => props.theme.size.small};
   line-height: 2.4rem;
   color: ${props => props.theme.color.fifthLight2};
   margin: 0;

   ${media.greaterThan("lg")`
      font-family: ${props => props.theme.font.primary};
      font-weight: 600;
      font-size: ${props => props.theme.size.big};
      line-height: 3rem;
   `}

`

const BottomBar = styled.div`
   position: absolute;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 70px;
   background-color: transparent;
   display: flex;
   justify-content: center;
   align-items: center;
`

const IconArrowDown = styled(FaArrowCircleDown)`
   width: 24px;
   height: 24px;
   color: ${props => props.theme.color.thirdBase};
`

export {
   BannerSection,
   WrapBanner,
   OverlayArea,
   OverlayContent,
   Title,
   Subtitle,
   BottomBar,
   IconArrowDown
}