import styled from "styled-components"

const Container = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background-color: ${props => props.theme.color.fifthDark1};
   padding: 3.2rem;
`

const Title = styled.h2`
   width: 100%;
   font-family: ${props => props.theme.font.primary};
   font-weight: 600;
   font-size: ${props => props.theme.size.big};
   line-height: 3.8rem;
   color: ${props => props.theme.color.grayZero};
   text-align: center;
`

const Obs = styled.p`
   width: 100%;
   font-family: ${props => props.theme.font.secondy};
   font-weight: 400;
   font-size: ${props => props.theme.size.verySmall};
   color: ${props => props.theme.color.grayZero};
   text-align: center;
`

export {
   Container,
   Title,
   Obs
}
