const cards = [
   {
      target: "/Espreguicadeiras",
      alt: "imagem do produto espregiçadeira",
      title: "Espreguiçadeiras",
      description: "Para sua casa e para hotéis e spas."
   },
   {
      target: "/Chaises",
      alt: "imagem do produto chaise e daybeds",
      title: "Chaise & Daybeds",
      description: "Diversos modelos."
   },
   {
      target: "/CortinasPersianas",
      alt: "imagem do produto cortinas e persianas",
      title: "Cortinas e Persianas",
      description: "Para todos os ambientes e estilos."
   },
]

const news = [
   {
      target: "/SofasPoltronas",
      alt: "imagem de poltrona área externa",
      title: "Poltronas",
   },
   {
      target: "/VasosVietnamitas",
      alt: "imagem de vaso vietnamita",
      title: "Vaso Vietnamita",
   },
   {
      target: "/CortinasPersianas",
      alt: "imagem de persiana",
      title: "Cortinas e Persianas",
   },
   {
      target: "/SofasPoltronas",
      alt: "imagem de sofás",
      title: "Sofás e Poltronas",
   },
   {
      target: "/Ombrelones",
      alt: "imagem de mesas, cadeiras e ombrelones",
      title: "Ombrelones",
   }
]

export { cards, news }