import React from "react"

import MainSection from "../../objects/MainSection"
import ButtonBudget from "../../objects/ButtonBudget"
import { Container, Title, Obs } from "./styles"

const BudgetSection = () => {
   return (
      <MainSection>
         <Container>
            <Title>Todos os podutos em até 12x sem juros*</Title>
            <Obs>* Parcela mínima de R$ 300,00</Obs>
            <ButtonBudget target="/Corporativo" label="Faça um orçamento"/>
         </Container>
      </MainSection>
   )
}

export default BudgetSection